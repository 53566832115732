import React from 'react'

import classMerge from 'utils/classMerge'

import CardFooter from './CardFooter'
import CardSection from './CardSection'
import CardSeparator from './CardSeparator'
import StickyCardFooter from './StickyCardFooter'

export interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  disabled?: boolean
  shadow?: boolean
}

const Card = ({ children, className, disabled, shadow = true, ...rest }: CardProps) => (
  <div
    className={classMerge(
      'inline-flex overflow-hidden rounded-xl',
      {
        'text-neutral-200 bg-neutral-50': disabled,
        'shadow-sm': shadow,
      },
      className
    )}
    {...rest}
  >
    {children}
  </div>
)

Card.Footer = CardFooter
Card.StickyFooter = StickyCardFooter
Card.Separator = CardSeparator
Card.Section = CardSection

export default Card
