import React from 'react'

import AlertCircle from 'icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'
import InformationCircle from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import DeleteCircle from 'icons/bold/01-Interface Essential/23-Delete/delete-2.svg'
import CheckCircle from 'icons/custom/check-circle.svg'
import classMerge from 'utils/classMerge'

import AlertActions from './AlertActions'
import AlertBody from './AlertBody'
import AlertCloseButton from './AlertCloseButton'
import AlertDetails from './AlertDetails'
import AlertLink from './AlertLink'
import AlertTitle from './AlertTitle'

const variants = {
  info: {
    defaultClassName: 'bg-white',
    Icon: InformationCircle,
    defaultIconClassName: 'fill-neutral-400  shadow-[#0000001f_0px_0px_0px_4px]',
  },
  success: {
    defaultClassName: 'text-tertiary-green-500',
    Icon: CheckCircle,
    defaultIconClassName: 'fill-tertiary-green-400 shadow-[#24934933_0px_0px_0px_4px]',
  },
  warning: {
    defaultClassName: 'text-secondary-orange-300',
    Icon: AlertCircle,
    defaultIconClassName: 'fill-secondary-orange-200 shadow-[#ffb4224f_0px_0px_0px_4px]',
  },
  error: {
    defaultClassName: 'text-error-default',
    Icon: DeleteCircle,
    defaultIconClassName: 'fill-error-default shadow-[#d23e3e33_0px_0px_0px_4px]',
  },
}

export interface AlertProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: 'info' | 'success' | 'warning' | 'error'
  noContainer?: boolean
  onDismiss?: () => void
}

const Alert = ({ variant = 'info', noContainer = false, onDismiss, className, children, ...rest }: AlertProps) => {
  const { defaultClassName, Icon, defaultIconClassName } = variants[variant]

  return (
    <div
      className={classMerge(
        'relative flex',
        {
          'shadow-xs border-neutral-100 border bg-white pl-4 pr-6 py-3 rounded-lg overflow-hidden': !noContainer,
        },
        defaultClassName,
        className
      )}
      {...rest}
    >
      <div className="min-w-[1rem] mr-3">
        <Icon className={classMerge('w-4 h-4 rounded-full', defaultIconClassName)} />
      </div>
      {onDismiss && <AlertCloseButton onClick={onDismiss} />}
      <div className="flex-1 space-y-2">{children}</div>
    </div>
  )
}

Alert.Body = AlertBody
Alert.Actions = AlertActions
Alert.Details = AlertDetails
Alert.Link = AlertLink
Alert.Title = AlertTitle

export default Alert
