import classNames from 'classnames'
import React from 'react'

const PublicFormFooter = ({
  children,
  className,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div
    className={classNames(
      'pt-3 mt-6 border-t border-neutral-75 text-neutral-800 flex flex-col items-center lg:justify-between lg:flex-row-reverse',
      className
    )}
  >
    {children}
  </div>
)

export default PublicFormFooter
