import { AxiosError, InternalAxiosRequestConfig } from 'axios'

const bearerInterceptor: [
  (config: InternalAxiosRequestConfig) => Promise<InternalAxiosRequestConfig>,
  (error: AxiosError) => AxiosError,
] = [
  async config => {
    const bearerToken = localStorage.getItem('bearer')

    if (bearerToken) {
      config.headers!.Authorization = `Bearer ${bearerToken}`
    }

    return config
  },
  error => error,
]

export default bearerInterceptor
