import React from 'react'

import classMerge from 'utils/classMerge'

const CardSection = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>) => (
  <div className={classMerge('flex flex-col md:flex-row p-6', className)} {...props}>
    {children}
  </div>
)

export default CardSection
