import { SpringValue, animated } from '@react-spring/web'
import React from 'react'

import CornerArrow from 'icons/core-solid/interface-essential/interface-arrows-bend-down-right-2.svg'
import EquationList from 'img/login/equation-list.png'
import EquationLogo from 'img/login/equation-logo.svg'
import EquationResult from 'img/login/equation-result.svg'

interface EquationFeatureProps {
  style: Omit<React.CSSProperties, 'transform'> & { transform: SpringValue<string> }
}

const EquationFeature = ({ style }: EquationFeatureProps) => {
  return (
    <animated.div style={style} className="h-full w-full bg-[#101727]">
      <div className="flex-col h-full w-full flex items-center justify-center">
        <div className="relative mb-12 flex flex-col items-center ">
          <div className="relative p-[14px] w-[360px] border border-white rounded-lg shadow-loginImage backdrop-blur-[1px] bg-white">
            <img src={EquationList} />

            <div className="w-[32px] h-[32px] absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 border-white rounded-lg shadow-loginImage bg-[#F3F4F6] flex items-center justify-center">
              <img src={EquationLogo} />
            </div>

            <div className="absolute bottom-0 left-0 scale-90 translate-x-[50%] translate-y-[150%] flex space-x-4 items-center">
              <CornerArrow className="w-[18px] h-[18px] mt-2 fill-white" />
            </div>
          </div>

          <div className="w-[280px] border border-white rounded-lg overflow-hidden shadow-loginImage bottom-24 backdrop-blur-[1px] bg-white/95 mt-4">
            <img src={EquationResult} />
          </div>
        </div>
        <div className="flex flex-col max-w-md text-center">
          <h1 className="mb-6 text-center text-white">Pricing equations</h1>
          <p className="mb-4 text-white leading-[1.125rem]">
            Calculate product prices in real-time by creating formulas that include values like dimensions or material
            costs.
          </p>
        </div>
      </div>
    </animated.div>
  )
}

export default EquationFeature
