import classNames from 'classnames'
import React from 'react'

import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

export interface TagProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  dismissible?: boolean
  onClose?: () => void
}

const Tag = ({ children, className, dismissible, onClose, ...rest }: TagProps) => (
  <span className={classNames('rounded px-1.5 py-1 font-p3 inline-flex h-min', className)} {...rest}>
    {children}
    {dismissible && (
      <button onClick={onClose} aria-label="close" className="">
        <Remove className="ml-1" width={8} height={8} />
      </button>
    )}
  </span>
)

export default Tag
