import React from 'react'

import classMerge from 'utils/classMerge'

const AlertActions = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div {...props} className={classMerge('flex justify-end space-x-3', className)}>
      {children}
    </div>
  )
}

export default AlertActions
