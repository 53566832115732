import React from 'react'

import classMerge from 'utils/classMerge'

const AlertBody = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div {...props} className={classMerge('space-y-2', className)}>
      {children}
    </div>
  )
}

export default AlertBody
