import classNames from 'classnames'
import React from 'react'

const StickyCardFooter = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <footer
    className={classNames(
      'sticky bottom-0 flex justify-end border-t border-neutral-100 px-6 py-3 bg-white rounded-b-xl',
      className
    )}
    {...props}
  >
    {children}
  </footer>
)

export default StickyCardFooter
