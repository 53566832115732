import classNames from 'classnames'
import React from 'react'

import BetaIcon from 'icons/custom/Flask-Half--Streamline-Core.svg'

import Tag from './Tag'
import Tooltip from './Tooltip'

export interface BetaTagProps {
  disableTooltip?: boolean
  className?: string
}

const BetaToolTip = ({ disableTooltip, children }: React.PropsWithChildren & { disableTooltip: boolean }) => (
  <Tooltip
    disabled={disableTooltip}
    content={
      <div>
        <div className="font-medium">Beta feature</div>
        <div className="mt-2">This feature is likely to continue to evolve and could become an add-on eventually.</div>
      </div>
    }
  >
    {children}
  </Tooltip>
)

const SmallBetaTag = ({ className, disableTooltip = false }: BetaTagProps) => {
  return (
    <BetaToolTip disableTooltip={disableTooltip}>
      <BetaIcon className={classNames(className, 'w-3 h-3 fill-tertiary-green-300')} />
    </BetaToolTip>
  )
}

const BetaTag = ({ className, disableTooltip = false }: BetaTagProps) => {
  return (
    <BetaToolTip disableTooltip={disableTooltip}>
      <Tag
        className={classNames(
          className,
          'text-white bg-gradient-to-r from-tertiary-green-300 to-tertiary-green-200/70 items-center'
        )}
      >
        <BetaIcon className={classNames('w-2.5 h-2.5 mr-1 fill-current')} />
        beta
      </Tag>
    </BetaToolTip>
  )
}

BetaTag.Small = SmallBetaTag

export default BetaTag
