import axios from 'axios'

import getAPIUrl from 'utils/getAPIUrl'

import bearerInterceptor from './bearerInterceptor'
import errorInterceptor from './errorInterceptor'
import loggingInfoHeaders from './loggingInfoHeaders'

const apiClient = axios.create({ baseURL: getAPIUrl() })

loggingInfoHeaders(apiClient)

apiClient.interceptors.request.use(...bearerInterceptor)
apiClient.interceptors.response.use(...errorInterceptor)

export default apiClient
