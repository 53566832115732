import React from 'react'

import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from 'utils/classMerge'

const AlertCloseButton = ({
  className,
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button {...props} className={classMerge('p-2 absolute right-2 top-2', className)}>
      <Remove className="fill-black" width={10} height={10} />
    </button>
  )
}

export default AlertCloseButton
