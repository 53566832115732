import React from 'react'

import classMerge from 'utils/classMerge'

const AlertDetails = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div {...props} className={classMerge('text-xs text-current text-neutral-400', className)}>
      {children}
    </div>
  )
}

export default AlertDetails
