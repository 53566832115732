import React, { useState } from 'react'

import Input, { InputProps } from './Input'

export type PasswordInputProps = Omit<InputProps, 'rightAddon'>

const PasswordInput = (props: PasswordInputProps) => {
  const [isShown, setIsShown] = useState(false)

  const handleTogglePassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setIsShown(value => !value)
  }

  return (
    <Input
      type={isShown ? 'text' : 'password'}
      rightAddon={
        <button tabIndex={-1} className="font-normal" onClick={handleTogglePassword} type="button">
          {isShown ? 'Hide' : 'Show'}
        </button>
      }
      {...props}
    />
  )
}

export default PasswordInput
